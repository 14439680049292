import React, { useEffect, useState } from "react";
import "./PrescriptionBehaviour.scss";
import { Row, Col, Card, Divider, Layout, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { getDecryptSession } from "../../../../../Shared/Utils/CommonUtils";
import { Content } from "antd/es/layout/layout";
import ReactEcharts from "echarts-for-react";

export default function PrescriptionBehaviour({ ...props }) {
  const [dropdownOptions, setDropdownOptions] = useState({
    portfolios: [],
    products: [],
    prescriptions: [],
  });
  const [dropdownData, setDropdownData] = useState({
    product: "",
    type: "portfolio",
    portfolio: ["ZORYVE"],
  });
  const [summaryData, setSummaryData] = useState();
  const [prescriptions, setPrescriptions] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [username, setUsername] = useState({});
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    fetchPrescriptionBehaviourData();
  }, []);

  useEffect(() => {
    if (
      dropdownData?.type === "products" &&
      dropdownData?.portfolio?.length > 0
    ) {
      let payload = { portfolio: dropdownData?.portfolio, profileId: id };
      fetchProductsList(payload);
    } else if (
      dropdownData?.type === "portfolio" &&
      dropdownData?.portfolio?.length > 0
    ) {
      fetchPrescriptionBehaviourData();
    }
  }, [dropdownData?.type, dropdownData?.portfolio]);

  const fetchPrescriptionBehaviourData = () => {
    let payload = {
      profileId: id,
      sectionId: Number(props?.menuId),
    };
    ServiceUtils.postRequest("kolProfile", payload).then(
      (response) => {
        if (response?.status === 200) {
          setSummaryData({ ...response.data?.data?.summaryData });
          setPrescriptions({ ...response.data?.data?.graphData });
          setUsername({...response.data?.data});
          setDropdownOptions({
            ...dropdownOptions,
            portfolios: response.data?.data?.portfolios,
          });
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };

  const fetchPrescriptionList = (payload) => {
    ServiceUtils.postRequest("prescription", payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setPrescriptions({ ...response.data.data?.graphData });
        } else if (response.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response.data.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching data");
        console.log(error);
      }
    );
  };

  const fetchProductsList = (payload) => {
    ServiceUtils.postRequest("products", payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setDropdownOptions({
            ...dropdownOptions,
            products: response.data.data,
          });
          setDropdownData({
            ...dropdownData,
            product: [response.data.data?.[0]?.value],
          });
          let payload = {
            product: [response.data.data?.[0]?.value],
            profileId: id,
            type: "products",
            portfolio: dropdownData?.portfolio,
          };
          fetchPrescriptionList(payload);
          setPrescriptions({});
        } else if (response.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response.data.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching data");
        console.log(error);
      }
    );
  };

  return (
    <>
      <div
        className="prescription-behaviour-root"
        data-test="prescription-behaviour-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">Prescription Behaviour {username?.user_name && `- ${username?.user_name}`}</p>
                <Card
                  className="prescriptionCard"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "15px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <div>
                        {summaryData?.label && (
                          <Card
                            style={{
                              borderRadius: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px 0",
                              }}
                            >
                              <p className="sub-header-label">
                                {summaryData?.label}
                              </p>
                            </div>
                            <Divider style={{ margin: 0 }} />
                            <p className="sub-header">
                              {summaryData?.subHeader}
                            </p>
                            <>
                              <Row gutter={[16, 16]}>
                                {summaryData?.values?.map(
                                  (prescriptionData, index) => (
                                    <Col
                                      key={index}
                                      xs={24}
                                      sm={12}
                                      md={8}
                                      lg={6}
                                    >
                                      <>
                                        <p className="labelSectionValueName">
                                          {prescriptionData?.label}
                                        </p>
                                        <p className="sectionValueName">
                                          {prescriptionData?.value || "-"}
                                        </p>
                                      </>
                                    </Col>
                                  )
                                )}
                              </Row>
                            </>
                          </Card>
                        )}
                        <Card
                          title="Prescribing Trend"
                          style={{ width: "100%", borderRadius: 10 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: 16,
                            }}
                          >
                            <Select
                              placeholder="Select Type"
                              size="large"
                              value={dropdownData?.type}
                              style={{ width: 200, marginRight: "20px" }}
                              showSearch
                              optionFilterProp="children"
                              onChange={async (type) => {
                                await setDropdownData({
                                  ...dropdownData,
                                  type,
                                });
                              }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              <Option value="portfolio">Portfolio</Option>
                              <Option value="products">Products</Option>
                            </Select>

                            <Select
                              mode="multiple"
                              size="large"
                              disabled={!dropdownData?.type}
                              placeholder="Select Portfolios"
                              value={dropdownData?.portfolio}
                              onChange={(values) => {
                                setDropdownData({
                                  ...dropdownData,
                                  portfolio: values,
                                });
                                //  if(dropdownData?.type === 'products' && values){
                                //   let payload = {portfolio:values,profileId:id}
                                //   fetchProductsList(payload);
                                //  }
                              }}
                              style={{ minWidth: 120, marginRight: "20px" }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {dropdownOptions?.portfolios?.map(
                                (productData) => (
                                  <Option
                                    key={productData?.key}
                                    value={productData?.key}
                                  >
                                    {productData?.value}
                                  </Option>
                                )
                              )}
                            </Select>

                            {dropdownData?.type === "products" &&
                              dropdownData?.portfolio?.length > 0 && (
                                <Select
                                  mode="multiple"
                                  size="large"
                                  placeholder="Select Products"
                                  value={dropdownData?.product}
                                  onChange={(values) => {
                                    setDropdownData({
                                      ...dropdownData,
                                      product: values,
                                    });
                                    let payload = {
                                      product: values,
                                      profileId: id,
                                      type: dropdownData?.type,
                                      portfolio: dropdownData?.portfolio,
                                    };
                                    fetchPrescriptionList(payload);
                                  }}
                                  style={{ minWidth: 120, marginRight: "20px" }}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {dropdownOptions?.products?.map(
                                    (productData) => (
                                      <Option
                                        key={productData?.key}
                                        value={productData?.key}
                                      >
                                        {productData?.value}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              )}
                          </div>
                          {prescriptions?.[0] ? (
                            <ReactEcharts
                              key={JSON.stringify(prescriptions[0])}
                              option={prescriptions?.[0]}
                              style={{ height: "300px", width: "100%" }}
                            />
                          ) : null}
                        </Card>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
}
