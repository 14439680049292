import React, { useEffect, useState } from 'react';
import { Timeline, Card, Row, Col } from 'antd';
import './ActivityTimeLine.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceUtils } from '../../../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../../../Shared/Utils/ToasterUtils';
import { getDecryptSession } from '../../../../../Shared/Utils/CommonUtils';

const ActivityTimeLine = (props) => {
  const [activityTimelineDetails, setActivityTimelineDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    fetchActivityTimelineDetails();
  }, []);


  const fetchActivityTimelineDetails = () => {
    let payload = {
      profileId: id,
      sectionId:Number(props?.menuId)
    }
    ServiceUtils.postRequest('kolProfile', payload).then(
      (response) => {
        if (response?.status === 200) {
          setActivityTimelineDetails({...response.data?.data});
        } 
        else if(response?.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response?.data?.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };
  return (
    <div className="activityTimeLineContainer">
    <p className="headerLabel">Activity Timeline {activityTimelineDetails?.user_name && `- ${activityTimelineDetails?.user_name}`}</p>
    <Card className='activityTimeLineCard'>
    <div style={{ display: 'flex', }}>
      <div style={{width: '75%'}}>
        <Timeline className="custom-timeline"  mode="left">
          {activityTimelineDetails?.activity_timeline?.map((monthData, index) => (
              <Timeline.Item key={index} 
              label={
                <div style={{ fontWeight: 'bold',backgroundColor:"white", fontSize: '16px' ,borderRadius:'10px'}}>
                  <p style={{margin:'10px 0'}}>{monthData.month}</p>
                </div>
              }>
                <Card style={{padding:'20px'}}>
              <Row gutter={[16, 16]}>
                {monthData.items.map((item, itemIndex) => (
                  <Col xs={24} sm={12} md={4} lg={4} key={itemIndex}>
                    <Card
                      style={{
                        backgroundColor: item.color,
                        textAlign: 'center',
                        borderRadius: '10px',
                        padding: '16px',
                        height: '100%',  // Ensures card maintains height consistency
                      }}
                    >
                      <h2>{item.count}</h2>
                      <p>{item.title}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
     {/* <Timeline
        mode='left'
        items={[
          {
            label: '2015-09-01',
            children: 'Create a services',
          },
          {
            label: '2015-09-01 09:12:11',
            children: 'Solve initial network problems',
          },
          {
            children: 'Technical testing',
          },
          {
            label: '2015-09-01 09:12:11',
            children: 'Network problems being solved',
          },
        ]}
      /> */}
    </Card>
    </div>
  );
};

export default ActivityTimeLine;
