import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Progress,
  List,
  Tooltip,
  Button,
  Layout,
  Avatar,
  Space,
  Image,
  Dropdown,
  theme,
  Divider,
  Select
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactEcharts from "echarts-for-react";
import { Content } from "antd/es/layout/layout";
import "./TLLScoreCard.scss";
import {
  UserOutlined
} from "@ant-design/icons";
import moreImage from "../../../../../Assets/images/3 dots.svg";
import TextArea from "antd/es/input/TextArea";
import PodiumDetails from "../../../../../Assets/json/podiumDetails.json"
// import ScoreCards from "../../../../../Assets/json/tllScoreCard.json"
import {Table, Badge} from "antd";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { useNavigate } from "react-router-dom";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { useParams } from "react-router-dom";
const { Title, Text } = Typography;

const TLLScoreCard = ({...props}) => {
  const Data = [
    {
      "key": 4,
      "id": 4,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    },
    {
      "key": 5,
      "id": 5,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    },
    {
      "key": 6,
      "id": 6,
      "created_by": "Murali Pinnaka",
      "statement": "Maui Derm 2/2024 - There has been nothing new for SD for years... until now.",
      "created_quarter": " 2/12/2024"
    }
  ];

  const items = [
    {
      label: <Space>Edit</Space>,
      key: '0',
      evtype:'edit'
    }
  ];

  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };


 const [cardContents,setCardContents] = useState({});
 const [marketing,setMarketingContent] = useState({});
 const [medic,setMedic] = useState({});
 const [competitive,setCompetitive] = useState({});
 const [edit,setEdit] = useState({})
 const [dropdownVisible, setDropdownVisible] = useState({
  tllCard: false,
  marketingCard: false,
  medicalAndSalesCard: false,
  competitiveInvolvementCard: false,
});

 const [nobleStatements, setNobleStatements] = useState({})
//  const [podiumData, setPodiumData] = useState(PodiumDetails);
 const [podiumData, setPodiumData] = useState([]);
 const [scorecard, setScoreCard] = useState({});
 const [guageChart, setGuageChart] = useState({});
 const [barChart, setBarChart] = useState({});
 const [year,setYear] = useState([]);
 const [defaultYear,setDefaultYear] = useState(null);
 const[imgSrc,setImgSrc] = useState("");
 const navigate = useNavigate();
 const { id } = useParams();

 const handleMoreClick = () => {
  const updatedBodyContent = [...podiumData.bodyContent,...Data];
  const updatedData = {
    headerContent: podiumData?.headerContent,
    bodyContent: updatedBodyContent
  }
  setPodiumData(updatedData);
}

 // Echarts Bar Chart Configuration
 const getHorizontalBarChartOption = () => {
  return {
    title: {
      text: "",
      left: "center",
      textStyle: {
        fontSize: 16,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "value",
      min: 0,
      max: 5,
      splitNumber: 5,
      axisLabel: {
        fontSize: 12,
      }
    },
    yAxis: {
      type: "category",
      data: ["Q1 2024", "Q2 2024", "Q3 2024", "Q4 2024"],
      axisLabel: {
        interval: 0,
        fontSize: 12,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Score",
        type: "bar",
        data: [4.0, 3.8, 5.0, 4.5],
        itemStyle: {
          color: "#215694", // Set the bar color here
        },
        barWidth: "50%",
        label: {
          show: true,
          position: "right", // Place the label on the right side for horizontal bars
          fontSize: 12,
        },
      },
    ],
  };
};


const getGaugeChartOption = () => {
  return {
    title: {
      text: "Latest Score",
      left: "center",
      textStyle: {
        fontSize: 16,
        fontWeight: "bold",
      },
    },
    series: [
      {
        name: "Gauge",
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 5,
        radius: "80%",
        splitNumber: 5,
        progress: {
          show: true,
          roundCap: true,
          width: 25,
          itemStyle: {
            color: "#59CD0D", // Set the progress color here
          },
        },
        pointer: {
          show: false, // Hide the pointer
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 25,
            color: [[1, "#D9D9D9"]],
          },
        },
        axisTick: {
          show: false, // Hide axis ticks
        },
        splitLine: {
          show: false, // Hide split lines
        },
        axisLabel: {
          show: false, // Hide axis labels
        },
        title: {
          show: false, // Hide the title inside the gauge
        },
        detail: {
          show: true,
          valueAnimation: true,
          fontSize: 16,
          color: "#777",
          formatter: "{value}/5",
          offsetCenter: [0, "40%"],
        },
        data: [
          {
            value: 4.5, // Latest score value
          },
        ],
      },
    ],
  };
};

const onYearChange = (value,label,name) => {
  setDefaultYear(value)
}


useEffect(()=>{
  fetchProfileData();
  fetchpodiumTableData();
  fetchCards();
},[])

useEffect(()=> {
  fetchScoreCardData();
},[defaultYear])

  const fetchProfileData = () => {
    let payload = {
      profileId: id,
      sectionId: Number(props?.menuId),
    };
    ServiceUtils.postRequest("kolProfile", payload).then(
      (response) => {
        if (response?.status === 200) {
          setImgSrc(response?.data?.data?.profile_picture)
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };

  const fetchCards = () => {
    let payload = {
      profileId: id
      };
    ServiceUtils.postRequest('tllScorecard', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setScoreCard(response?.data?.data?.dataJson);
          setCardContents({...response?.data?.data?.dataJson?.cardContent});
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }

  const fetchpodiumTableData = () => {
    let payload = {
      profileId: id
      };
    ServiceUtils.postRequest('podiumStatements', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          let columns = response?.data?.data?.podium_noble_statements;
          columns.headerContent.map((el) => {
            if (el.key === 'statement') {
              el.render = (statement) => {
                return (
                  <ul>
                    {statement.map((desc, ind) => (
                      <li key={ind}>{desc}</li>
                    ))}
                  </ul>
                );
              };
            }
          }
          )
          setPodiumData(columns);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }

  
  const fetchScoreCardData = () => {
    let payload = {
      profileId:id,
      advYear:defaultYear
    }
    ServiceUtils.postRequest('advScore', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setGuageChart(response?.data?.data?.scoreCard?.guage_chart_options);
          // setBarChart(response?.data?.data?.scoreCard?.horizantal_bar_options);
          const customRatings = response?.data?.data?.scoreCard?.horizantal_bar_options?.ratings;
          // const customRatings = {
          //   'Q1 2024': 'Rated 5 Stars',
          //   'Q2 2024': '',
          //   'Q3 2024': '',
          //   'Q4 2024': 'Rated 4 Stars'
          // };
          
          setBarChart({
            ...response?.data?.data?.scoreCard?.horizantal_bar_options,
            tooltip: {
              ...response?.data?.data?.scoreCard?.horizantal_bar_options.tooltip,
              formatter: function (params) {
                const yearLabel = params[0].name; // Y-axis data label (like "Q1 2024")
                const rating = customRatings[yearLabel] || 'No rating'; 
                return ` ${rating}`; 
              }
            }
          });          
          setYear(response?.data?.data?.scoreCard?.years);
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while fetching Objective.')
      }
    );
  }

  /**
   * To do individual save for each card data
   * @param {*} type  card type
   * @param {*} value edited value of textarea
   */
  const handleEditAndSave = (type, value) => {
    const filteredContent = value.filter(item => item.trim() !== ''); // Exclude empty strings
    // console.log("Filtered TextArea Data:", filteredContent);
    let payload = {
      profileId: id,
      type: type,
      data: filteredContent
    }
    ServiceUtils.postRequest('updateCards', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          alertService.success('Saved successfully.')
        }
        else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error('Error while saving the data.')
      }
    );
  };

  return (
    <>
      <div
        className="Tll-scorecard-root"
        data-test="Tll-scorecard-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">TLL Advocacy Score & Engagement Plan</p>
                <Card
                  styles={{body:{padding:'20px'}}}
                  className="Tll-scorecard-Card"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "15px",
                  }}
                >
                  <div>
                    <Row gutter={[16, 16]}>
                      {/* Profile Section */}
                      <Col xs={24} md={8}>
                        <Card>
                          <Row>
                            <Col span={24}>
                            <Row>
                            <Col span={24}>
                            <Avatar
                                style={{
                                  verticalAlign: "middle",
								  margin: '8px 7rem'
                                }}
                                size={120}
                                src={imgSrc}
                              >
                                <UserOutlined style={{fontSize:"100px"}}/>
                              </Avatar>
                              </Col>
                              {/* <Col span={16} className="userDetails">
                              <Title style={{ margin:0}} level={3}>Neel Bhatia</Title>
                              <p style={{margin:0,fontSize:"18px",fontWeight:"500"}}>1255349288</p>
                              <p style={{ margin:0,fontSize:'18px',fontWeight:"500"}}>Technical Director</p>
                              </Col> */}
                              </Row>
                            </Col>
                            <Col span={24} style={{ marginTop: "20px" }}>
                              <Card bordered={false}>
                              {/* className="" style={{ display: "flex" }} */}
                                <Row gutter={8}>
                                  <Col span={10}>
                                    <Title level={5}>Select The Plan</Title>
                                  </Col>
                                  <Col span={12}>
                                    <Select
                                      defaultValue="2024"
                                      // value={kols}
                                      name="engagementYears"
                                      allowClear
                                      style={{
                                        width: '90%',
                                      }}
                                      showSearch
                                      placeholder="Please select"
                                      onChange={(value, label) => { onYearChange(value, label, "kols") }}
                                      options={year}
                                    // filterOption={filter}
                                    // maxTagCount="responsive"
                                    />
                                  </Col>
                                </Row>
                                <ReactEcharts
                                // getGaugeChartOption()
                            option={guageChart}
                            style={{ height: "220px", marginTop: "20px" }}
                          />
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      {/* Advocacy Score and Bar Chart */}
                      <Col xs={24} md={16}>
                        <Card>
                          <Row justify="space-between" align="middle">
                            {/* <Title level={5}>
                              Advocacy Score (Latest Quarter)
                            </Title> */}
                            <Tooltip title="Click to see the Advocacy Score Description">
                              <Button
                                icon={<InfoCircleOutlined />}
                                shape="circle"
                              />
                            </Tooltip>
                          </Row>
                          <ReactEcharts
                          // getHorizontalBarChartOption()
                            option={barChart}
                            style={{ height: "380px", marginTop: "20px" }}
                          />
                        </Card>
                      </Col>

                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: "20px"}}>
                      <Col xs={24}>
                        <Card
                          title={"TLL"}
                          extra={
                            <div style={{ display: 'flex' }}>
                              <Dropdown
                                visible={dropdownVisible.tllCard}
                                onVisibleChange={(flag) => setDropdownVisible({...dropdownVisible,tllCard: true})}
                                placement="bottom"
                                dropdownRender={(menu) => (
                                  <div style={contentStyle}>
                                    <Space className='pointer'
                                      onClick={() => {
                                        setEdit({ ...edit, tllCard: true });
                                        setDropdownVisible({...dropdownVisible,tllCard: false});
                                      }}
                                      style={{
                                        padding: 8,
                                      }}
                                    >
                                      Edit
                                    </Space>
                                  </div>
                                )}
                                trigger={['click']}
                              >
                                <p onClick={(e) => { e.preventDefault() }}>
                                  <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                                    <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                                  </Space>
                                </p>
                              </Dropdown>
                            </div>
                          }
                          styles={{ header: { backgroundColor: "#F3E8E8" } }}
                        >
                          {edit?.tllCard ? (
                            <div>
                              <TextArea
                                value={cardContents?.tllCardContent.join('\n')}
                                onChange={(e) => setCardContents({ ...cardContents, tllCardContent: e.target.value.split('\n') })}
                                rows={6}
                                style={{ width: '100%' }}
                              />
                              <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                                <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, tllCard: false }) }}>Cancel</Button>
                                <Button type="primary" onClick={() => {
                                  handleEditAndSave("tll",cardContents?.tllCardContent);
                                  setEdit({ ...edit, tllCard: false });
                                }}>Save</Button>
                              </Row>
                            </div>
                          ) :
                            <List
                              dataSource={cardContents?.tllCardContent}
                              renderItem={(item) => (
                                <List.Item>
                                  <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span> {/* Bullet point */}
                                  {item}
                                </List.Item>
                              )}
                            />}
                        </Card>
                      </Col>

                      <Col xs={24}>
                        <Card
                        title={"Marketing"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        extra={
                          <div style={{ display: 'flex' }}>
                            <Dropdown
                              visible={dropdownVisible.marketingCard}
                              onVisibleChange={(flag) => setDropdownVisible({...dropdownVisible,marketingCard: true})}
                              placement="bottom"
                              dropdownRender={(menu) => (
                                <div style={contentStyle}>
                                  <Space className='pointer'
                                    onClick={() => {
                                      setEdit({ ...edit, marketingCard: true });
                                      setDropdownVisible({...dropdownVisible,marketingCard: false});
                                    }}
                                    style={{
                                      padding: 8,
                                    }}
                                  >
                                    Edit
                                  </Space>
                                </div>
                              )}
                              trigger={['click']}
                            >
                              <p onClick={(e) => { e.preventDefault() }}>
                                <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                                  <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                                </Space>
                              </p>
                            </Dropdown>
                          </div>
                        }
                        >
                             {edit?.marketingCard ? (
                            <div>
                              <TextArea
                                value={scorecard?.marketing.join('\n')}
                                onChange={(e) => setScoreCard({ ...scorecard, marketing: e.target.value.split('\n') })}
                                rows={6}
                                style={{ width: '100%' }}
                              />
                              <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                                <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, marketingCard: false }) }}>Cancel</Button>
                                <Button type="primary" onClick={() => {
                                  handleEditAndSave("marketing",scorecard?.marketing);
                                  setEdit({ ...edit, marketingCard: false });
                                }}>Save</Button>
                              </Row>
                            </div>
                          ) :
                            <List
                              dataSource={scorecard?.marketing}
                              renderItem={(item) => (
                                <List.Item>
                                  <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                                  {item}
                                </List.Item>
                              )}
                            />}
                          {/* <List
                            dataSource={scorecard?.marketing}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          /> */}
                        </Card>
                      </Col>
                      <Col xs={24}>
                        <Card
                        title={"Medical and Sales"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        extra={
                          <div style={{ display: 'flex' }}>
                            <Dropdown
                              visible={dropdownVisible.medicalAndSalesCard}
                              onVisibleChange={(flag) => setDropdownVisible({...dropdownVisible,medicalAndSalesCard: true})}
                              placement="bottom"
                              dropdownRender={(menu) => (
                                <div style={contentStyle}>
                                  <Space className='pointer'
                                    onClick={() => {
                                      setEdit({ ...edit, medicalAndSalesCard: true });
                                      setDropdownVisible({...dropdownVisible,medicalAndSalesCard: false});
                                    }}
                                    style={{
                                      padding: 8,
                                    }}
                                  >
                                    Edit
                                  </Space>
                                </div>
                              )}
                              trigger={['click']}
                            >
                              <p onClick={(e) => { e.preventDefault() }}>
                                <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                                  <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                                </Space>
                              </p>
                            </Dropdown>
                          </div>
                        }
                        >
                          {edit?.medicalAndSalesCard ? (
                            <div>
                              <TextArea
                                value={scorecard?.medical_and_sales.join('\n')}
                                onChange={(e) => setScoreCard({ ...scorecard, medical_and_sales: e.target.value.split('\n') })}
                                rows={6}
                                style={{ width: '100%' }}
                              />
                              <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                                <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, marketingCard: false }) }}>Cancel</Button>
                                <Button type="primary" onClick={() => {
                                  handleEditAndSave("medical_and_sales",scorecard?.medical_and_sales);
                                  setEdit({ ...edit, medicalAndSalesCard: false });
                                }}>Save</Button>
                              </Row>
                            </div>
                          ) :
                            <List
                              dataSource={scorecard?.medical_and_sales}
                              renderItem={(item) => (
                                <List.Item>
                                  <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                                  {item}
                                </List.Item>
                              )}
                            />}
                          {/* <List
                          dataSource={scorecard?.medical_and_sales}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          /> */}
                        </Card>
                      </Col>
                      <Col xs={24}>
                        <Card
                        title={"Competitive Involvement"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        extra={
                          <div style={{ display: 'flex' }}>
                            <Dropdown
                              visible={dropdownVisible.competitiveInvolvementCard}
                              onVisibleChange={(flag) => setDropdownVisible({...dropdownVisible,competitiveInvolvementCard: true})}
                              placement="bottom"
                              dropdownRender={(menu) => (
                                <div style={contentStyle}>
                                  <Space className='pointer'
                                    onClick={() => {
                                      setEdit({ ...edit, competitiveInvolvementCard: true });
                                      setDropdownVisible({...dropdownVisible,competitiveInvolvementCard: false});
                                    }}
                                    style={{
                                      padding: 8,
                                    }}
                                  >
                                    Edit
                                  </Space>
                                </div>
                              )}
                              trigger={['click']}
                            >
                              <p onClick={(e) => { e.preventDefault() }}>
                                <Space className='pointer' style={{ marginTop: '12px', fontSize: '20px' }}>
                                  <Image src={moreImage} alt="More" height={"15px"} style={{ alignItems: "center", display: "flex" }} preview={false} />
                                </Space>
                              </p>
                            </Dropdown>
                          </div>
                        }
                        >
                          {edit?.competitiveInvolvementCard ? (
                            <div>
                              <TextArea
                                value={scorecard?.competitive_involvement.join('\n')}
                                onChange={(e) => setScoreCard({ ...scorecard, competitive_involvement: e.target.value.split('\n') })}
                                rows={6}
                                style={{ width: '100%' }}
                              />
                              <Row gutter={[16, 16]} style={{ marginTop: "20px", marginRight: "5px", justifyContent: "flex-end" }}>
                                <Button style={{ marginRight: "10px" }} onClick={() => { setEdit({ ...edit, competitiveInvolvementCard: false }) }}>Cancel</Button>
                                <Button type="primary" onClick={() => {
                                  handleEditAndSave("competitive_involvement",scorecard?.competitive_involvement);
                                  setEdit({ ...edit, competitiveInvolvementCard: false });
                                }}>Save</Button>
                              </Row>
                            </div>
                          ) :
                            <List
                              dataSource={scorecard?.competitive_involvement}
                              renderItem={(item) => (
                                <List.Item>
                                  <span style={{ marginRight: '8px', fontWeight: 'bold', fontSize: "15px" }}>•</span>
                                  {item}
                                </List.Item>
                              )}
                            />}
                          
                          {/* <List
                          dataSource={scorecard?.competitive_involvement}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          /> */}
                        </Card>
                      </Col>
                      <Col xs={24}>
                        <Card
                        title={"Podium Noble Statements"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}}
                        extra={<Button type="primary">More</Button>} >
                          {/* onClick={handleMoreClick} */}
                          <Table
                            columns={podiumData?.headerContent}
                            dataSource={podiumData?.bodyContent}
                            rowKey={(record) => record.id}
                          />
                          {/* <List
                            dataSource={[
                              'Maui Derm 2/2024 - "There has been nothing new for SD for years... until now".',
                              "SDDS - Highlighted rapid itch relief, durable response and reiterated that it is a disease predominately of inflammation.",
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          /> */}
                        </Card>
                      </Col>
                     
                    </Row>

                    {/* <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col xs={24} md={12}>
                        <Card
                        title={"Podium Noble Statements"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} >
                          <List
                            dataSource={[
                              'Maui Derm 2/2024 - "There has been nothing new for SD for years... until now".',
                              "SDDS - Highlighted rapid itch relief, durable response and reiterated that it is a disease predominately of inflammation.",
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                      <Col xs={24} md={12}>
                        <Card
                        title={"Competitive Involvement"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} >
                          <List
                            dataSource={[
                              "Advisor - Multiple Companies.",
                              'Author - "Tapinarof cream 1% once daily for the treatment of plaque psoriasis: Patient-reported outcomes from the PSOARING 3 trial".',
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                    </Row> */}
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default TLLScoreCard;
