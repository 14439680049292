import React, { useEffect, useState } from "react";
import { Table, Badge, Tabs, Card, Tooltip } from "antd";
import "./ActivityDetails.scss";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { useNavigate, useParams } from "react-router-dom";
import { getDecryptSession } from "../../../../../Shared/Utils/CommonUtils";

const { TabPane } = Tabs;

const ActivityDetails = (props) => {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [activityDetails, setActivityDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
  }, []);

  useEffect(() => {
    if (activeTab === "1") {
      fetchActivityDetails();
    } else {
      fetchTabDetails();
    }
  }, [activeTab]);

  const fetchActivityDetails = () => {
    let payload = {
      profileId: id,
      sectionId: Number(props?.menuId),
      tabId: activeTab,
    };
    ServiceUtils.postRequest("kolProfile", payload).then(
      (response) => {
        if (response?.status === 200) {
          const columns = generateColumns(
            response.data?.data?.data?.headerContent
          );
          if (tabs?.length === 0) {
            setTabs(response.data?.data?.tabs);
          }
          setActivityDetails({
            bodyContent: response.data?.data?.data?.bodyContent,
            headerContent: columns,
            user_name: response.data?.data?.user_name,
          });
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };
  const fetchTabDetails = () => {
    let payload = {
      profileId: id,
      sectionId: Number(props?.menuId),
      tabId: activeTab,
    };
    ServiceUtils.postRequest("intTabs", payload).then(
      (response) => {
        if (response?.status === 200) {
          const columns = generateColumns(
            response.data?.data?.data?.headerContent
          );
          setActivityDetails({
            user_name:activityDetails?.user_name,
            bodyContent: response.data?.data?.data?.bodyContent,
            headerContent: columns,
          });
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };

  const generateColumns = (headerContent) => {
    return headerContent.map((column) => {
      // Render the "outcome" column as a Badge
      if (column.dataIndex === "outcome" && column.render) {
        return {
          title: column.title,
          dataIndex: column.dataIndex,
          key: column.key,
          render: (outcome) => (
            <Badge
              status={
                column.render[outcome.toLowerCase().replace(" ", "")] || "default"
              }
              text={outcome}
            />
          ),
        };
      }
      
      // Render the "guideline_name" column with a Tooltip and ellipsis
      else if (column?.dataIndex === "guideline_name") {
        return {
          title: column.title,
          dataIndex: column.dataIndex,
          key: column.key,
          width: 200,
          render: (guideline_name) => (
            <Tooltip title={guideline_name}>
              <div
                style={{
                  maxWidth: "500px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {guideline_name}
              </div>
            </Tooltip>
          ),
        };
      }
  
      // Render the "conference" column with clickable conf_route
      else if (column.dataIndex === "conference") {
        return {
          title: column.title,
          dataIndex: column.dataIndex,
          key: column.key,
          render: (conference, record) => (
            <span className="link" onClick={()=>navigate(record.conf_route)}>{conference}</span>
          ),
        };
      }
  
      // Render the "meeting_name" column with clickable mtg_route
      else if (column.dataIndex === "meeting_name") {
        return {
          title: column.title,
          dataIndex: column.dataIndex,
          key: column.key,
          render: (meeting_name, record) => (
            <span className="link" onClick={()=>navigate(record.mtg_route)}>{meeting_name}</span>
          ),
        };
      }
  
      // Default case
      else {
        return {
          title: column.title,
          dataIndex: column.dataIndex,
          key: column.key,
        };
      }
    });
  };
  
  // Apply row color based on the "color" field
  const onRow = (record) => {
    return {
      style: {
        backgroundColor: record.color || "transparent",
        transition: "background-color 0.3s ease", 
      },
    };
  }

  // Handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="activityDetailsContainer" style={{ width: "100%" }}>
      <p className="headerLabel">Activity Details {activityDetails?.user_name && `- ${activityDetails?.user_name}`}</p>
      <Card className="activityDetailsCard">
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {tabs?.map((tab) => (
            <TabPane tab={tab.label} key={tab.value}>
              <Table
                columns={activityDetails?.headerContent}
                dataSource={activityDetails?.bodyContent}
                onRow={onRow}
              />
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </div>
  );
};

export default ActivityDetails;
